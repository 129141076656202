import { getLocation } from '../../common/store/location/location-selectors';
import { setLocation } from '../../common/store/location/location-actions';

export default function createRouterMiddleware(getRouter) {
  return store => next => action => {
    switch (action.type) {
      case '@@router/CALL_HISTORY_METHOD':
        if (action.payload.method === 'push') {
          const router = getRouter();
          const location = getLocation(store.getState());
          const pathname = action.payload.args[0];
          if (router) {
            const newLocation = { ...location, pathname };
            store.dispatch(setLocation(newLocation));
            router.match(pathname);
          }
        }

      default:
        next(action);
    }
  };
}
